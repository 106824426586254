import axios from "axios";
import { useState, useEffect } from "react";

export default function CompanyStats() {
  const [billionsDonated, setBillionsDonated] = useState(0);
  const [charitiesBenefited, setCharitiesBenefited] = useState(0);
  const [peopleGiven, setPeopleGiven] = useState(0);

  const getCompanyStats = async () => {
    return axios
      .get(
        `https://company.api-east.marketing.charitableimpact.com/v1/company/company_stats`
      )
      .then((res) => {
        setBillionsDonated(res.data.billions_donated);
        setCharitiesBenefited(res.data.charities_benefited);
        setPeopleGiven(res.data.people_given);
      })
      .catch(function (error) {
        console.log(error);
        return "false";
      });
  };

  useEffect(() => {
    getCompanyStats();
  }, [billionsDonated, charitiesBenefited, peopleGiven]);

  return [billionsDonated, charitiesBenefited, peopleGiven];
}
