import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  width: 100%;
  background-image: url(/who-we-are/bg.png);
  background-position: center;
  background-size: cover;
`;

const AllBannerIcons = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 70px;

  @media (max-width: 570px) {
    padding-top: 30px;
  }
`;

const BannerHeading = styled.div`
  margin: auto;
  text-align: center;
  padding-bottom: 40px;

  @media (max-width: 1199px) {
    padding-top: 50px;
  }

  h1 {
    font-size: 40px;
    color: #424242;
    line-height: 50px;
    font-family: ProximaNova-Light;

    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
`;

const BannerWho1 = styled.div`
  position: relative;
  width: 385px;
  height: 244px;
  display: inline-block;
  margin-right: 8px;

  &::after {
    content: "";
    position: absolute;
    background-image: url(/who-we-are/ribbon-icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 65px;
    height: 65px;
    left: -24px;
    bottom: -34px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 1199px) {
    width: 198px;
  }

  @media (max-width: 570px) {
    width: 210px;
  }

  @media (max-width: 470px) {
    width: 160px;
  }

  img {
    @media (max-width: 1199px) {
      width: 100%;
      height: 244px;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
`;

const BannerWho2 = styled.div`
  position: relative;
  height: 244px;
  display: inline-block;
  margin-right: 8px;
  width: 203px;

  @media (max-width: 1199px) {
    width: 133px;
  }
  @media (max-width: 768px) {
    display: none;
  }

  img {
    @media (max-width: 1199px) {
      width: 100%;
      height: 244px;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
`;

const BannerWho3 = styled.div`
  position: relative;
  height: 244px;
  display: inline-block;
  margin-right: 8px;
  width: 203px;

  &::after {
    content: "";
    position: absolute;
    background-image: url(/who-we-are/dots.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 200px;
    height: 200px;
    top: -74px;
    left: 56px;
    z-index: -9;

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 1199px) {
    width: 133px;
  }

  @media (max-width: 570px) {
    width: 160px;
    margin-right: 4px;
  }

  @media (max-width: 470px) {
    width: 120px;
  }

  img {
    @media (max-width: 1199px) {
      width: 100%;
      height: 244px;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
`;

const BlockOrange = styled.div`
  background-color: #fcc7ad;
  width: 37px;
  height: 244px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: -119px;

  @media (max-width: 570px) {
    display: none;
  }
`;

const BlockOrangeMob = styled.div`
  display: none;
  @media (max-width: 570px) {
    background-color: #fcc7ad;
    width: 34px;
    height: 244px;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: -119px;
  }
`;

const BlockGreen = styled.div`
  background-color: #009ea1;
  width: 23px;
  height: 244px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: -119px;

  @media (max-width: 570px) {
    display: none;
  }
`;

const BlockYellow = styled.div`
  background-color: #f5b047;
  width: 49px;
  height: 244px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: -119px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url(/who-we-are/canada-icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 65px;
    height: 65px;
    left: 14px;
    top: 44px;
  }

  @media (max-width: 570px) {
    display: none;
  }
`;

const AnimSlideUp = {
  "data-sal": "slide-up",
  "data-sal-duration": "2000",
};

const BannerSection: React.FC = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <SectionWrap>
      <Container>
        <AllBannerIcons style={{ transform: `translateY(-${offsetY * 2}px)` }}>
          <BannerHeading>
            <h1>Who we are</h1>
          </BannerHeading>
          <BannerWho1 {...AnimSlideUp} data-sal-delay="0s">
            <img src="/who-we-are/banner-1.png" alt="banner" loading="lazy" />
          </BannerWho1>
          <BannerWho2 {...AnimSlideUp} data-sal-delay="200">
            <img src="/who-we-are/banner-2.png" alt="banner" loading="lazy" />
          </BannerWho2>
          <BlockOrange {...AnimSlideUp} data-sal-delay="700" />
          <BannerWho3 {...AnimSlideUp} data-sal-delay="400">
            <img src="/who-we-are/banner-3.png" alt="banner" loading="lazy" />
          </BannerWho3>
          <BlockOrangeMob {...AnimSlideUp} data-sal-delay="500" />
          <BlockGreen {...AnimSlideUp} data-sal-delay="600" />
          <BlockYellow {...AnimSlideUp} data-sal-delay="700" />
        </AllBannerIcons>
      </Container>
    </SectionWrap>
  );
};

export default BannerSection;
