import React from "react";
import Layout from "../components/Layout/Layout";
import BannerSection from "../components/WhoWeAre/BannerSection";
import OurFounder from "../components/WhoWeAre/OurFounder";
import WhatWeDo from "../components/WhoWeAre/WhatWeDo";
import WhereWeStarted from "../components/WhoWeAre/WhereWeStarted";
import WhoWeAreDonation from "../components/WhoWeAre/WhoWeAreDonation";

const pageInfo = {
  parent_path: "/about",
  path: "/who-we-are",
  title: "Who we are",
  description:
    "We're a public foundation that operates as a donor-advised fund. Our team is focused on helping people create the change they want to see in the world.",
  image: "/who-we-are/what-we-do.png",
};

const WhoWeArePage = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <WhatWeDo />
      <WhereWeStarted />
      <OurFounder />
      <WhoWeAreDonation />
    </Layout>
  );
};

export default WhoWeArePage;
