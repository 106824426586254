import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  padding: 3rem 0 2rem;

  @media (max-width: 767.98px) {
    text-align: center;
  }

  @media (max-width: 570px) {
    padding: 1rem 0 2rem;
  }

  h2 {
    color: #282828;
  }

  p {
    font-size: 16px;
  }
`;

const LayoutImageRight = styled.div`
  .mobile-img {
    @media (max-width: 991px) {
      display: none;
    }
  }
`;

const AnimFade = {
  "data-sal": "fade",
  "data-sal-duration": "2000",
  "data-sal-delay": "200",
};

const WhatWeDo: React.FC = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={10} lg={{ offset: 1 }} {...AnimFade}>
            <h2>Everyone has something they want to change in the world</h2>
          </Col>
        </Row>
        <LayoutImageRight className="py-3 justify-content-center" {...AnimFade}>
          <Row>
            <Col md={12} lg={{ span: 5, offset: 1 }} className="pe-md-5">
              <p>
                Charitable Impact was created to help you create the change you
                want to see in the world. We’re a public foundation that
                operates as a donor-advised fund. In short, this means you can
                manage your charitable giving from a single account, which we
                call the Impact Account.
              </p>
              <p>
                Our Impact Account comes with powerful tools that help you plan
                the impact you want to make, and find and give to your favourite
                charities all from one place. We also connect you to a wider
                giving community by making it easier to give with friends,
                family, and others who care about the same things you do.
              </p>
              <p>
                Along the way, our team is here to offer support and help you
                achieve your giving goals, whatever they might be.
              </p>
              <p>
                We’re here for you no matter what causes you choose to support,
                how much you give, or how experienced you are with charitable{" "}
                giving.
              </p>
            </Col>

            <Col md={5} className="mobile-img">
              <img
                className="img-fluid"
                src="/who-we-are/what-we-do.png"
                alt=""
                loading="lazy"
              />
            </Col>
          </Row>
        </LayoutImageRight>
      </Container>
    </SectionWrap>
  );
};

export default WhatWeDo;
