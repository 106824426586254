import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #f5f8fc;

  h2 {
    color: #282828;
    font-weight: 600 !important;
    font-family: ProximaNova-Light !important;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 29px;

    @media (max-width: 767.98px) {
      padding: 0 2rem;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  p {
    font-family: ProximaNova-Regular;
    text-align: left;
  }

  .tab-text {
    @media (max-width: 991px) {
      width: 60%;
      margin: auto;
      text-align: justify;
      float: none;
      display: block;
      -ms-flex: 0 0 60%;
      -webkit-box-flex: 0;
      flex: 0 0 60%;
      margin-top: 50px;
    }
    @media (max-width: 768px) {
      width: 80%;
      -ms-flex: 0 0 80%;
      flex: 0 0 80%;
      margin-top: 20px;
    }
    @media (max-width: 570px) {
      text-align: center;
      width: 90%;
      -ms-flex: 0 0 90%;
      flex: 0 0 90%;
    }
    &.left-text-we-are {
      max-width: 500px !important;
    }
  }

  img {
    &.img-fluid {
      &.img-tab {
        @media (max-width: 991px) {
          width: 60%;
          margin: auto;
          text-align: center;
          float: none;
          display: block;
        }
        @media (max-width: 768px) {
          width: 80%;
        }
        @media (max-width: 570px) {
          width: 90%;
        }
      }
    }
  }
`;

const AnimFade = {
  "data-sal": "fade",
  "data-sal-duration": "2000",
};

const WhereWeStarted: React.FC = () => {
  return (
    <SectionWrap>
      <Container fluid>
        <Row>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="ps-0"
            {...AnimFade}
            data-sal-delay="200"
          >
            <img
              className="img-fluid img-tab"
              src="/who-we-are/where-we-started.jpg"
              alt=""
              loading="lazy"
            />
          </Col>
          <Col
            md={12}
            lg={6}
            xl={6}
            className="tab-text left-text-we-are"
            {...AnimFade}
            data-sal-delay="400"
          >
            <h2>Where we started, where we're going</h2>
            <p>
              We were founded in Vancouver, Canada in 2011 by John Bromley. It
              all started with the goal of making it easier for people to come
              together to change the world, and it led to developing resources
              for donors that support them on their giving journey.
            </p>
            <p>
              Today, our talented team has grown to include engineers,
              designers, operations staff, and other dedicated team members who
              are focused on helping people create the change they want to see
              in the world.
            </p>
            <p>
              We know that giving can and should be an enjoyable, uplifting, and
              personally meaningful experience. We see a future where this is
              the norm, not the exception. A future where everyone regularly
              makes an impact on the things that matter most to them as part of
              going about their daily life.
            </p>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default WhereWeStarted;
