import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CompanyStats from "../../utils/CompanyStats";
import Fancybox from "../Fancybox";

const SectionWrap = styled.section`
  .our-founder-img {
    display: block;
    position: relative;

    img {
      float: right;
      max-width: 494px;
      width: 100%;

      @media (max-width: 991px) {
        float: none;
        max-width: 100%;
        width: 50%;
        margin: auto;
        text-align: center;
        display: block;
        margin-top: 50px;
      }
      @media (max-width: 768px) {
        width: 80%;
      }
    }

    a {
      img {
        height: 167px;
        width: 258px;
        border-radius: 4px;
        -webkit-box-shadow: 0 4px 16px 0 rgba(70, 70, 70, 0.5);
        box-shadow: 0 4px 16px 0 rgba(70, 70, 70, 0.5);

        @media (max-width: 991px) {
          margin-top: 82px;
          height: 147px;
          width: 218px;
        }

        @media (max-width: 570px) {
          margin-top: 76px;
          height: 167px;
        }
      }
    }
  }

  .founder-box {
    @media (max-width: 991px) {
      width: 60%;
      margin: auto;
      text-align: justify;
      float: none;
      display: block;
      -ms-flex: 0 0 60%;
      -webkit-box-flex: 0;
      flex: 0 0 60%;
    }
    @media (max-width: 768px) {
      width: 80%;
      text-align: center;
      -ms-flex: 0 0 80%;
      -webkit-box-flex: 0;
      flex: 0 0 80%;
    }

    h2 {
      @media (max-width: 991px) {
        text-align: center;
        margin-bottom: 25px;
      }
      @media (max-width: 767.98px) {
        margin-top: 50px;
        margin-bottom: 20px;
      }
      @media (max-width: 470px) {
        margin-top: 125px;
        margin-bottom: 20px;
      }
    }

    p {
      @media (max-width: 470px) {
        text-align: justify;
      }
    }
  }
`;

const Connect = styled.div`
  padding: 80px 0;

  @media (max-width: 991px) {
    padding: 80px 0 20px 0;
  }

  @media (max-width: 768px) {
    padding: 69px 40px;
  }

  @media (max-width: 630px) {
    padding: 55px 30px !important;
  }

  @media (max-width: 420px) {
    padding: 59px 10px !important;
  }
`;

const AnimFade = {
  "data-sal": "fade",
  "data-sal-duration": "2000",
};

const VideoBox = styled.div`
  position: absolute;
  bottom: 0;
  width: 258px;
  left: 0;
  right: 0;
  margin: auto;
  top: 250px;

  a {
    position: relative;
    display: block;
    height: 167px;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url(/who-we-are/playicon.svg);
      background-size: contain;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      transition: 1s;
      z-index: 9;
    }
  }

  @media (max-width: 1199px) {
    top: 210px;
  }

  @media (max-width: 991px) {
    top: 112px;
  }

  @media (max-width: 768px) {
    top: 128px;
  }

  @media (max-width: 570px) {
    bottom: 280px;
  }
`;

const OurFounder: React.FC = () => {
  const [billionsDonated] = CompanyStats();
  return (
    <SectionWrap>
      <Container className="pb-5">
        <Connect className="justify-content-center">
          <Row>
            <Col
              lg={{ span: 5, offset: 1 }}
              xl={5}
              md={{ span: 12, order: 1 }}
              className="founder-box  order-2"
              {...AnimFade}
              data-sal-delay="500"
            >
              <h2>Our Founder</h2>
              <p>
                John Bromley grew up with charity at the core of his family’s
                values. He started his career in corporate finance working with PwC and RBC Capital Markets, and then went to work with world-renowned experts in charity law.
              </p>
              <p>
                After developing an in-depth understanding of the complex world
                underpinning the charity sector, John recognized a need to make
                giving more accessible and more effective. He saw that while
                wanting to help others comes naturally, knowing how to make a
                difference through charitable giving does not.
              </p>
              <p>
                With that in mind, John went on to launch Charitable Impact. Its
                mission? Nurture the generosity within each person, and bring
                the resources for creating change in the world to everyone.
              </p>
              <p>
                To date, Charitable Impact has facilitated more than $
                {billionsDonated}
                {` `}billion in donations and is helping more and more people
                build the brighter future they imagine.
              </p>
            </Col>
            <Col
              md={{ span: 12, order: 2 }}
              lg={6}
              xl={6}
              className="our-founder-img order-1"
              {...AnimFade}
              data-sal-delay="200"
            >
              <img
                className="img-fluid video-img"
                src="/who-we-are/our-founder.png"
                alt="video image"
                loading="lazy"
              />

              <Fancybox options={{ infinite: false }}>
                <VideoBox>
                  <a
                    data-fancybox="https://www.youtube.com/embed/_dhah7Dcz5Q"
                    href="https://www.youtube.com/embed/_dhah7Dcz5Q"
                  >
                    <img
                      src="https://i3.ytimg.com/vi/_dhah7Dcz5Q/hqdefault.jpg"
                      alt=""
                      loading="lazy"
                    />
                  </a>
                </VideoBox>
              </Fancybox>
            </Col>
          </Row>
        </Connect>
      </Container>
    </SectionWrap>
  );
};

export default OurFounder;
