import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CompanyStats from "../../utils/CompanyStats";

const SectionWrap = styled.section`
  background: linear-gradient(
    180deg,
    #f5f8fc 0%,
    rgba(245, 248, 252, 0.99) 82.66%,
    rgba(245, 248, 252, 0) 100%
  );
`;

const SectionDonation = styled.section`
  padding: 17rem 0 18rem 0;
  overflow: hidden;
  max-width: 1400px;
  margin: auto;

  h2 {
    width: 60%;
    margin: 0 auto;

    @media (max-width: 1199px) {
      width: 50%;
    }
    @media (max-width: 570px) {
      width: 89% !important;
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
  }
`;

const Connect = styled.div``;

const Cols = styled(Col)`
  position: relative;

  img {
    position: absolute;
    z-index: 1;

    &:nth-child(1) {
      left: 275px;
      top: -165px;
      width: 21px;
      height: 85px;

      @media (max-width: 767.98px) {
        left: 1.5rem;
        top: -8rem;
      }
    }

    &:nth-child(2) {
      left: 24%;
      top: -165px;

      @media (max-width: 767.98px) {
        left: 3.2rem;
        top: -8rem;
      }
    }
    &:nth-child(3) {
      right: 0;
      top: -188px;

      @media (max-width: 767.98px) {
        display: none;
      }
    }
    &:nth-child(4) {
      right: 84px;
      top: -70px;

      @media (max-width: 767.98px) {
        top: -14rem;
        right: 7.2rem;
        width: 49px;
        height: 21px;
      }
      @media (max-width: 570px) {
        top: -11.9rem;
        right: 7.2rem;
        width: 49px !important;
        height: 14px !important;
      }
    }
    &:nth-child(5) {
      right: 10%;
      top: -70px;

      @media (max-width: 991px) {
        right: 0;
        top: -70px;
      }
      @media (max-width: 767.98px) {
        top: -14rem;
        right: 1.5rem;
        width: 84px;
        height: 84px;
      }
      @media only screen and (max-width: 400px) and (max-width: 767.98px) {
        top: -14rem;
        right: 1.5rem;
      }
      @media (max-width: 570px) {
        top: -190px;
        right: 20px;
      }
    }
    &:nth-child(6) {
      right: 9%;
      top: 120px;

      @media (max-width: 991px) {
        right: -1%;
        top: 120px;
      }
      @media (max-width: 767.98px) {
        right: -2.5rem;
        top: 10rem;
      }
      @media only screen and (max-width: 400px) and (max-width: 767.98px) {
        right: -2.5rem;
        top: 16rem !important;
      }
      @media (max-width: 570px) {
        right: -11% !important;
        top: 249px !important;
      }
    }
    &:nth-child(7) {
      z-index: 2;
      right: 14%;
      top: 175px;

      @media (max-width: 991px) {
        right: -1%;
        top: 175px;
      }
      @media (max-width: 767.98px) {
        right: 2rem;
        top: 14rem;
      }
      @media only screen and (max-width: 400px) and (max-width: 767.98px) {
        right: 2rem;
        top: 14rem;
      }
      @media (max-width: 570px) {
        right: 3% !important;
        top: 297px !important;
      }
    }
    &:nth-child(8) {
      z-index: 2;
      right: 22%;
      top: 175px;

      @media (max-width: 991px) {
        right: 12%;
        top: 175px;
      }
      @media (max-width: 767.98px) {
        right: 8.2rem;
        top: 14rem;
      }
      @media only screen and (max-width: 400px) and (max-width: 767.98px) {
        right: 7rem !important;
        top: 21rem !important;
      }
      @media (max-width: 570px) {
        right: 27% !important;
        top: 298px !important;
      }
    }
    &:nth-child(9) {
      z-index: 2;
      left: 28px;
      top: -65px;

      @media (max-width: 991px) {
        left: -92px;
        top: -65px;
      }
      @media (max-width: 768px) {
        left: -92px;
        top: 5px;
      }
      @media (max-width: 767.98px) {
        left: -6rem;
        top: 10rem;
      }
      @media (max-width: 570px) {
        left: -90px !important;
        top: 175px !important;
      }
    }
    &:nth-child(10) {
      z-index: 2;
      left: -8px;
      top: 60px;

      @media (max-width: 991px) {
        left: -78px;
        top: 60px;
      }
      @media (max-width: 768px) {
        left: -8px;
        top: 140px !important;
      }
      @media (max-width: 767.98px) {
        width: 93px;
        height: 50px;
      }
      @media only screen and (max-width: 400px) and (max-width: 767.98px) {
        left: 1rem !important;
        top: 25.5rem !important;
      }
      @media (max-width: 570px) {
        left: 42px !important;
        top: 320px !important;
      }
    }
    &:nth-child(11) {
      z-index: 2;
      left: 180px;
      top: 140px;

      @media (max-width: 991px) {
        left: 80px;
        top: 140px;
      }
      @media (max-width: 767.98px) {
        width: 50px;
        height: 50px;
      }
      @media only screen and (max-width: 400px) and (max-width: 767.98px) {
        left: 1rem !important;
        top: 28.6rem !important;
      }
      @media (max-width: 570px) {
        left: 42px !important;
        top: 370px !important;
      }
    }
    &:nth-child(12) {
      z-index: 2;
      left: 143px;
      top: 182px;

      @media (max-width: 991px) {
        left: 43px;
        top: 182px;
      }
      @media (max-width: 767.98px) {
        top: 24.5rem;
        left: inherit;
      }
      @media only screen and (max-width: 400px) and (max-width: 767.98px) {
        right: 1rem !important;
        top: 30rem !important;
        left: inherit;
      }
      @media (max-width: 570px) {
        right: 0px !important;
        top: 542px !important;
      }
    }
  }
`;

const AnimFade = {
  "data-sal": "fade",
  "data-sal-duration": "2000",
};

const WhoWeAreDonation: React.FC = () => {
  const [billionsDonated] = CompanyStats();

  return (
    <SectionWrap>
      <SectionDonation>
        <Container>
          <Connect className="text-center">
            <Row>
              <Cols md={12}>
                <img
                  src="/who-we-are/turqbar-2.png"
                  height="78"
                  width="33"
                  {...AnimFade}
                  data-sal-delay="100"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/photo-12.png"
                  height="85"
                  width="109"
                  {...AnimFade}
                  data-sal-delay="200"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/photo-11.png"
                  height="103"
                  width="154"
                  {...AnimFade}
                  data-sal-delay="300"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/bar-6.png"
                  height="29"
                  width="69"
                  {...AnimFade}
                  data-sal-delay="400"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/photo-13.png"
                  height="117"
                  width="117"
                  {...AnimFade}
                  data-sal-delay="500"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/shape-2.png"
                  height="111"
                  width="120"
                  {...AnimFade}
                  data-sal-delay="600"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/photo-14.png"
                  height="85"
                  width="93"
                  {...AnimFade}
                  data-sal-delay="700"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/bar-7.png"
                  height="10"
                  width="69"
                  {...AnimFade}
                  data-sal-delay="800"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/shape-1.png"
                  height="195"
                  width="248"
                  {...AnimFade}
                  data-sal-delay="900"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/photo-16.png"
                  height="117"
                  width="217"
                  {...AnimFade}
                  data-sal-delay="1000"
                  alt=""
                  loading="lazy"
                />
                <img
                  src="/who-we-are/photo-15.png"
                  height="117"
                  width="117"
                  {...AnimFade}
                  data-sal-delay="1100"
                />
                <img
                  src="/who-we-are/bar-8.png"
                  height="24"
                  width="32"
                  {...AnimFade}
                  data-sal-delay="1200"
                  alt=""
                  loading="lazy"
                />
                <h2>
                  More than{" "}
                  <span style={{ color: "#009EA1" }}>
                    ${billionsDonated} billion has been donated
                  </span>{" "}
                  by the Charitable Impact community, with thousands of
                  charities benefiting from the generosity of donors across
                  Canada.
                </h2>
              </Cols>
            </Row>
          </Connect>
        </Container>
      </SectionDonation>
    </SectionWrap>
  );
};

export default WhoWeAreDonation;
